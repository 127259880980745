// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paginationEmpty--NsdaS {
  display: none;
}
.pagination--SodzY {
  padding: 0px;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  margin: 24px 0;
  justify-content: center;
  line-height: 19px;
}
.pagination--SodzY .ant-pagination-item {
  border: none;
}
.pagination--SodzY .ant-pagination-disabled {
  display: none;
}
.pagination--SodzY .ant-pagination-item-active .paginationButton--btWJ6 {
  background-color: #00217D !important;
  border: none !important;
}
.pagination--SodzY .ant-pagination-item-active .paginationButton--btWJ6 .copy--W5QhF {
  color: white;
}
.pagination--SodzY .ant-pagination-item-active .paginationButton--btWJ6 .mobileNavigationButton--DCboj {
  width: 42px;
}
.pagination--SodzY .ant-pagination-item:focus {
  outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/pagination/pagination.module.less"],"names":[],"mappings":"AAEA;EACE,aAAA;AADF;AAIA;EACE,YAAA;EACA,qBAAA;EACA,aAAA;EACA,mBAAA;EACA,cAAA;EACA,uBAAA;EACA,iBAAA;AAFF;AALA;EAUI,YAAA;AAFJ;AARA;EAcI,aAAA;AAHJ;AAXA;EAmBM,oCAAA;EACA,uBAAA;AALN;AAfA;EAuBQ,YAAA;AALR;AAlBA;EA2BQ,WAAA;AANR;AArBA;EAiCI,aAAA;AATJ","sourcesContent":["@import '~@boclips-ui/styles/src/colors';\n\n.paginationEmpty {\n  display: none;\n}\n\n.pagination {\n  padding: 0px;\n  list-style-type: none;\n  display: flex;\n  flex-direction: row;\n  margin: 24px 0;\n  justify-content: center;\n  line-height: 19px;\n\n  :global(.ant-pagination-item) {\n    border: none;\n  }\n\n  :global(.ant-pagination-disabled) {\n    display: none;\n  }\n\n  :global(.ant-pagination-item-active) {\n    .paginationButton {\n      background-color: @blue-800 !important;\n      border: none !important;\n\n      .copy {\n        color: white;\n      }\n\n      .mobileNavigationButton {\n        width: 42px;\n      }\n    }\n  }\n\n  :global(.ant-pagination-item):focus {\n    outline: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginationEmpty": `paginationEmpty--NsdaS`,
	"pagination": `pagination--SodzY`,
	"paginationButton": `paginationButton--btWJ6`,
	"copy": `copy--W5QhF`,
	"mobileNavigationButton": `mobileNavigationButton--DCboj`
};
export default ___CSS_LOADER_EXPORT___;
