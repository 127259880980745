// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heroWrapper--YGEOV {
  height: 432px;
  background: #F9FBFF;
  border-radius: 0.25rem;
}
.heroCopyWrapper--bOCyn {
  max-width: 570px;
}
.heroCopyWrapper--bOCyn a {
  color: inherit !important;
}
.svgWrapper--gXbu4 {
  width: 427px;
}
.svgWrapper--gXbu4 svg {
  max-width: 427px;
}
`, "",{"version":3,"sources":["webpack://./src/components/hero/style.module.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,sBAAA;AADF;AAIA;EAIE,gBAAA;AALF;AACA;EAEI,yBAAA;AAAJ;AAKA;EACE,YAAA;AAHF;AAEA;EAII,gBAAA;AAHJ","sourcesContent":["@import '@boclips-ui/styles/src/colors';\n\n.heroWrapper {\n  height: 432px;\n  background: @blue-100;\n  border-radius: 0.25rem;\n}\n\n.heroCopyWrapper {\n  a {\n    color: inherit !important;\n  }\n  max-width: 570px;\n}\n\n.svgWrapper {\n  width: 427px;\n\n  svg {\n    max-width: 427px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heroWrapper": `heroWrapper--YGEOV`,
	"heroCopyWrapper": `heroCopyWrapper--bOCyn`,
	"svgWrapper": `svgWrapper--gXbu4`
};
export default ___CSS_LOADER_EXPORT___;
