// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textButton--iiGam {
  margin: 4px 0;
  --bo-ui-button-text-colour: #00217D;
  --bo-ui-button-padding: 0.25rem;
  --bo-ui-button-border: 0;
  --bo-ui-button-border-radius: 4px;
  --bo-ui-button-box-shadow-colour: transparent;
  --bo-ui-button-background-color: none;
  --bo-ui-button-background-color-hover: #D9E3FF;
  --bo-ui-button-background-color-active: #BCCDFF;
  --bo-ui-button-svg-stroke-colour: #00217D;
  --bo-ui-button-svg-stroke-colour-active: #001550;
  --bo-ui-button-text-colour-active: #001550;
  --bo-ui-button-focus-outline-offset: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/textButton/style.module.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mCAAA;EACA,+BAAA;EACA,wBAAA;EACA,iCAAA;EACA,6CAAA;EACA,qCAAA;EACA,8CAAA;EACA,+CAAA;EACA,yCAAA;EACA,gDAAA;EACA,0CAAA;EACA,wCAAA;AADF","sourcesContent":["@import '@boclips-ui/styles/src/colors';\n\n.textButton {\n  margin: 4px 0;\n  --bo-ui-button-text-colour: @blue-800;\n  --bo-ui-button-padding: 0.25rem;\n  --bo-ui-button-border: 0;\n  --bo-ui-button-border-radius: 4px;\n  --bo-ui-button-box-shadow-colour: transparent;\n  --bo-ui-button-background-color: none;\n  --bo-ui-button-background-color-hover: @blue-400;\n  --bo-ui-button-background-color-active: @blue-500;\n  --bo-ui-button-svg-stroke-colour: @blue-800;\n  --bo-ui-button-svg-stroke-colour-active: @blue-900;\n  --bo-ui-button-text-colour-active: @blue-900;\n  --bo-ui-button-focus-outline-offset: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textButton": `textButton--iiGam`
};
export default ___CSS_LOADER_EXPORT___;
