// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#bodyAfter--BY9Mn {
  content: '';
  width: 8px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.toast--gjKRZ {
  position: relative;
  overflow: hidden;
}
.toast--gjKRZ button {
  align-self: center;
}
.toastSuccess--sgzCv {
  --toastify-color-light: #ECF4EE;
}
.toastSuccess--sgzCv .body--Ia2_g:after {
  content: '';
  width: 8px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #0A855C;
}
.toastError--Um55d {
  --toastify-color-light: #FFEFEF;
}
.toastError--Um55d .body--Ia2_g:after {
  content: '';
  width: 8px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #DF0000;
}
.body--Ia2_g {
  color: #1A202C;
  font-family: 'Rubik', Helvetica Neue, Helvetica, Arial, sans-serif;
  padding-left: 1rem;
}
.title--yTNsE {
  font-weight: 500;
  font-size: 1rem;
}
.message--Bh1Rt {
  font-weight: 400;
  font-size: 0.75rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/notification/displayNotification.module.less"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,UAAA;EACA,YAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;AADF;AAIA;EACE,kBAAA;EACA,gBAAA;AAFF;AAAA;EAKI,kBAAA;AAFJ;AAMA;EACE,+BAAA;AAJF;AAOI;EArBF,WAAA;EACA,UAAA;EACA,YAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EAkBI,mBAAA;AAAN;AAKA;EACE,+BAAA;AAHF;AAMI;EAhCF,WAAA;EACA,UAAA;EACA,YAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EA6BI,mBAAA;AACN;AAIA;EACE,cAAA;EACA,kEAAA;EACA,kBAAA;AAFF;AAKA;EACE,gBAAA;EACA,eAAA;AAHF;AAMA;EACE,gBAAA;EACA,kBAAA;AAJF","sourcesContent":["@import '~@boclips-ui/styles/src/colors';\n\n#bodyAfter {\n  content: '';\n  width: 8px;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n\n.toast {\n  position: relative;\n  overflow: hidden;\n\n  button {\n    align-self: center;\n  }\n}\n\n.toastSuccess {\n  --toastify-color-light: @success-bg;\n\n  .body {\n    &:after {\n      #bodyAfter;\n      background: @success;\n    }\n  }\n}\n\n.toastError {\n  --toastify-color-light: @error-bg;\n\n  .body {\n    &:after {\n      #bodyAfter;\n      background: @error;\n    }\n  }\n}\n\n.body {\n  color: @gray-900;\n  font-family: 'Rubik', Helvetica Neue, Helvetica, Arial, sans-serif;\n  padding-left: 1rem;\n}\n\n.title {\n  font-weight: 500;\n  font-size: 1rem;\n}\n\n.message {\n  font-weight: 400;\n  font-size: 0.75rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bodyAfter": `bodyAfter--BY9Mn`,
	"toast": `toast--gjKRZ`,
	"toastSuccess": `toastSuccess--sgzCv`,
	"body": `body--Ia2_g`,
	"toastError": `toastError--Um55d`,
	"title": `title--yTNsE`,
	"message": `message--Bh1Rt`
};
export default ___CSS_LOADER_EXPORT___;
