// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo--EBSuB {
  padding-top: 0.5rem;
  padding-bottom: 0.25rem;
}
.logo--EBSuB img[alt] {
  font-size: 0.75rem;
}
.logo--EBSuB svg {
  height: 43px;
  width: auto;
  shape-rendering: geometricPrecision;
}
@media screen and (min-width: 1148px) {
  .logo--EBSuB svg {
    height: 46px;
    width: auto;
    shape-rendering: geometricPrecision;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/logo/style.module.less"],"names":[],"mappings":"AAEA;EACE,mBAAA;EACA,uBAAA;AADF;AADA;EAKI,kBAAA;AADJ;AAJA;EASI,YAAA;EACA,WAAA;EACA,mCAAA;AAFJ;AAMA;EACE;IAEI,YAAA;IACA,WAAA;IACA,mCAAA;EALJ;AACF","sourcesContent":["@import '~@boclips-ui/styles/src/breakpoints';\n\n.logo {\n  padding-top: 0.5rem;\n  padding-bottom: 0.25rem;\n\n  img[alt] {\n    font-size: 0.75rem;\n  }\n\n  svg {\n    height: 43px;\n    width: auto;\n    shape-rendering: geometricPrecision;\n  }\n}\n\n@media screen and (min-width: @screen-lg) {\n  .logo {\n    svg {\n      height: 46px;\n      width: auto;\n      shape-rendering: geometricPrecision;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `logo--EBSuB`
};
export default ___CSS_LOADER_EXPORT___;
