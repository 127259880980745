// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchWrapper--D808c {
  --bo-ui-search-bar-height: 48px;
  --bo-ui-button-width-icon-only: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/components/searchBar/style.module.less"],"names":[],"mappings":"AAAA;EACE,+BAAA;EACA,oCAAA;AACF","sourcesContent":[".searchWrapper {\n  --bo-ui-search-bar-height: 48px;\n  --bo-ui-button-width-icon-only: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchWrapper": `searchWrapper--D808c`
};
export default ___CSS_LOADER_EXPORT___;
