// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.googleClassroomLink--GWJfJ {
  font-weight: 500;
  padding-left: 0.5rem;
  text-decoration: none !important;
  margin-top: 0.5rem
}
`, "",{"version":3,"sources":["webpack://./src/components/shareLinkButton/googleClassroom/GoogleClassroomShareLink.module.less"],"names":[],"mappings":"AACI;EAAA,gBAAA;EACA,oBAAA;EACA,gCAAA;EACA;AAHA","sourcesContent":[".googleClassroomLink {\n    @apply font-medium;\n    padding-left: 0.5rem;\n    text-decoration: none !important;\n    margin-top: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"googleClassroomLink": `googleClassroomLink--GWJfJ`
};
export default ___CSS_LOADER_EXPORT___;
