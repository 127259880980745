// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer--LyuPu {
  grid-column-start: 2;
  grid-column-end: 26;
  padding: 1rem 0;
  order: 2;
  border-top: 2px solid #D9E3FF;
}
.link--wIkFj {
  position: relative;
  margin-left: 1rem;
  font-weight: 500 !important;
}
.link--wIkFj:before {
  color: #00217D;
  content: '•';
  position: absolute;
  left: -0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/layout/footer.module.less"],"names":[],"mappings":"AAGE;EAAA,oBAAA;EAAA,mBAAA;EACA,eAAA;EACA,QAAA;EACA;AAHA;AAMF;EACE,kBAAA;EACA,iBAAA;EACA,2BAAA;AAFF;AAIE;EACE,cAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;AAFJ","sourcesContent":["@import '~@boclips-ui/styles/src/colors';\n\n.footer {\n  @apply col-start-2 col-end-26;\n  padding: 1rem 0;\n  order: 2;\n  border-top: 2px solid @blue-400;\n}\n\n.link {\n  position: relative;\n  margin-left: 1rem;\n  font-weight: 500 !important;\n\n  &:before {\n    color: @blue-800;\n    content: '•';\n    position: absolute;\n    left: -0.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `footer--LyuPu`,
	"link": `link--wIkFj`
};
export default ___CSS_LOADER_EXPORT___;
