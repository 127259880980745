// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shareLinkButton--kxpsG {
  --bo-ui-button-svg-height: 24px;
  --bo-ui-button-svg-width: 24px;
}
.shareLinkBodalButtons--yB2GH {
  flex-direction: column;
  align-content: center;
  justify-content: center !important;
  margin-top: 1rem;
}
@media screen and (min-width: 768px) {
  .shareLinkBodalButtons--yB2GH {
    margin-bottom: 1.5rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/shareLinkButton/shareLinkButton.module.less"],"names":[],"mappings":"AAEA;EACE,+BAAA;EACA,8BAAA;AADF;AAIA;EACE,sBAAA;EACA,qBAAA;EACA,kCAAA;EACA,gBAAA;AAFF;AAKA;EACE;IACE,qBAAA;EAHF;AACF","sourcesContent":["@import '~@boclips-ui/styles/src/breakpoints';\n\n.shareLinkButton {\n  --bo-ui-button-svg-height: 24px;\n  --bo-ui-button-svg-width: 24px;\n}\n\n.shareLinkBodalButtons {\n  flex-direction: column;\n  align-content: center;\n  justify-content: center !important;\n  margin-top: 1rem;\n}\n\n@media screen and (min-width: @screen-md) {\n  .shareLinkBodalButtons {\n    margin-bottom: 1.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shareLinkButton": `shareLinkButton--kxpsG`,
	"shareLinkBodalButtons": `shareLinkBodalButtons--yB2GH`
};
export default ___CSS_LOADER_EXPORT___;
