// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thumbnailsContainer--p8laV {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
  grid-column-gap: 0.25rem;
  grid-row-gap: 0.25rem;
  height: 174px;
}
.thumbnailsContainer--p8laV > div:first-child {
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 1;
}
.thumbnail--VLLMr {
  border-radius: 0.25rem;
  height: 175px;
  background: #D9E3FF;
}
.thumbnails--fIVvJ {
  border-radius: 0.25rem;
  height: 100%;
  background: #D9E3FF;
}
`, "",{"version":3,"sources":["webpack://./src/components/playlists/thumbnails/style.module.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,gDAAA;EACA,6CAAA;EACA,wBAAA;EACA,qBAAA;EACA,aAAA;AADF;AAII;EACE,iBAAA;EACA,eAAA;EACA,oBAAA;EACA,kBAAA;AAFN;AAOA;EACE,sBAAA;EACA,aAAA;EACA,mBAAA;AALF;AAQA;EACE,sBAAA;EACA,YAAA;EACA,mBAAA;AANF","sourcesContent":["@import '~@boclips-ui/styles/src/colors';\n\n.thumbnailsContainer {\n  display: grid;\n  grid-template-columns: repeat(2, minmax(0, 1fr));\n  grid-template-rows: repeat(2, minmax(0, 1fr));\n  grid-column-gap: 0.25rem;\n  grid-row-gap: 0.25rem;\n  height: 174px;\n\n  & > div {\n    &:first-child {\n      grid-row-start: 1;\n      grid-row-end: 3;\n      grid-column-start: 1;\n      grid-column-end: 1;\n    }\n  }\n}\n\n.thumbnail {\n  border-radius: 0.25rem;\n  height: 175px;\n  background: @blue-400;\n}\n\n.thumbnails {\n  border-radius: 0.25rem;\n  height: 100%;\n  background: @blue-400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thumbnailsContainer": `thumbnailsContainer--p8laV`,
	"thumbnail": `thumbnail--VLLMr`,
	"thumbnails": `thumbnails--fIVvJ`
};
export default ___CSS_LOADER_EXPORT___;
