// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skipToContent--ZPuTI {
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 1;
  position: relative;
  z-index: 1;
}
.skipToContent--ZPuTI button {
  opacity: 0;
  pointer-events: none;
}
.skipToContent--ZPuTI.focused--IfqQy button {
  opacity: 0;
  pointer-events: all;
}
.skipToContent--ZPuTI.focused--IfqQy button:focus {
  opacity: 1;
}
.skipToContent--ZPuTI:focus-within {
  z-index: 3;
}
`, "",{"version":3,"sources":["webpack://./src/components/skipLink/style.module.less"],"names":[],"mappings":"AACE;EAAA,oBAAA;EAAA,kBAAA;EAAA,iBAAA;EAAA,eAAA;EACA,kBAAA;EACA;AAFA;AADF;EAMI,UAAA;EACA,oBAAA;AACJ;AAEE;EAEI,UAAA;EACA,mBAAA;AADN;AAGM;EACE,UAAA;AADR;AAME;EACE,UAAA;AAJJ","sourcesContent":[".skipToContent {\n  @apply col-start-2 col-end-5 row-start-1 row-end-1;\n  position: relative;\n  z-index: 1;\n\n  button {\n    opacity: 0;\n    pointer-events: none;\n  }\n\n  &.focused {\n    button {\n      opacity: 0;\n      pointer-events: all;\n\n      &:focus {\n        opacity: 1;\n      }\n    }\n  }\n\n  &:focus-within {\n    z-index: 3;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skipToContent": `skipToContent--ZPuTI`,
	"focused": `focused--IfqQy`
};
export default ___CSS_LOADER_EXPORT___;
