// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heroWrapper--o2dgR {
  height: 432px;
  border-radius: 0.25rem;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(12, 1fr);
}
.heroCopyWrapper--dvHXa {
  grid-column: 7 / 12;
}
.svgWrapper--GwTqg {
  grid-column: 2 / 7;
  justify-self: flex-end;
  width: 427px;
  display: flex;
  justify-content: flex-end;
}
.svgWrapper--GwTqg svg {
  max-width: 427px;
  max-height: 330px;
}
.svgWrapper--GwTqg svg path {
  stroke: #495A7C;
}
`, "",{"version":3,"sources":["webpack://./src/components/playlists/emptyState/style.module.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,aAAA;EACA,uBAAA;EACA,sCAAA;AADF;AAIA;EACE,mBAAA;AAFF;AAKA;EACE,kBAAA;EACA,sBAAA;EACA,YAAA;EACA,aAAA;EACA,yBAAA;AAHF;AAFA;EAQI,gBAAA;EACA,iBAAA;AAHJ;AANA;EAYM,eAAA;AAHN","sourcesContent":["@import '../../../../node_modules/@boclips-ui/styles/src/colors';\n\n.heroWrapper {\n  height: 432px;\n  border-radius: 0.25rem;\n  display: grid;\n  grid-template-rows: 1fr;\n  grid-template-columns: repeat(12, 1fr);\n}\n\n.heroCopyWrapper {\n  grid-column: 7 / 12;\n}\n\n.svgWrapper {\n  grid-column: 2 / 7;\n  justify-self: flex-end;\n  width: 427px;\n  display: flex;\n  justify-content: flex-end;\n\n  svg {\n    max-width: 427px;\n    max-height: 330px;\n\n    path {\n      stroke: @gray-700;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heroWrapper": `heroWrapper--o2dgR`,
	"heroCopyWrapper": `heroCopyWrapper--dvHXa`,
	"svgWrapper": `svgWrapper--GwTqg`
};
export default ___CSS_LOADER_EXPORT___;
