// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copyLinkButton--rvfDL {
  --bo-ui-button-outline-background-color-hover: transparent;
  --bo-ui-button-outline-svg-fill: transparent;
  --bo-ui-button-outline-svg-stroke: #00217D;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/copyLinkButton/style.module.less"],"names":[],"mappings":"AAEA;EACE,0DAAA;EACA,4CAAA;EACA,0CAAA;AADF","sourcesContent":["@import '~@boclips-ui/styles/src/colors';\n\n.copyLinkButton {\n  --bo-ui-button-outline-background-color-hover: transparent;\n  --bo-ui-button-outline-svg-fill: transparent;\n  --bo-ui-button-outline-svg-stroke: @blue-800;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copyLinkButton": `copyLinkButton--rvfDL`
};
export default ___CSS_LOADER_EXPORT___;
